<template>
  <section class="summary-wrapper">
    <div class="summary-contain">
      <div class="title">公司简介</div>
      <div class="divider" />
      <div class="text">
        {{
          name
        }}，是作业帮旗下教育数字化综合服务商，秉持“科技赋能，让智慧教育触手可及”的使命，致力于以科技为杠杆，重塑数字化教与学新模式。公司将行业领先的人工智能、大数据、云计算等技术与教育教学场景深度融合，为政府和学校提供数字化资源、数字化应用、数字化治理等综合服务，支撑核心教育场景数字化转型。
      </div>
      <div class="text">
        截至目前，云思智学高质量作业、课后服务、智慧体育、智慧考试等核心产品已覆盖全国20多个省市自治区、超200个区县，服务学校超1500所、师生超100万人，有力推动教育数字化转型和教育高质量发展。
      </div>
    </div>
    <div class="summary-contain">
      <div class="title">公司领导</div>
      <div class="divider" />
      <div v-scroll-lazy="{ mode: 'bg', src: introImg }" class="intro-img">
        <div class="people-box">
          <div class="info">
            <div class="name">苏静</div>
            <div class="infoText">作业帮执行总裁</div>
          </div>
          <div class="desc">
            北京云思智学科技有限公司董事长，毕业于中国人民大学法学院、英国诺丁汉大学，原百度公共政策研究院院长、原百度专利事务部高级总监。2018年北京市三八红旗手。北京市海淀区青联委员。
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import HOST from '@/common/host'
import img from './assets/sujing.png'
export default defineComponent({
  name: 'DeskSummary',
  setup() {
    const introImg = img

    let name = ''

    for (const item in HOST) {
      if (HOST[item].regex.test(location.hostname)) {
        name = HOST[item].name
      }
    }

    return {
      name,
      introImg
    }
  }
})
</script>

<style lang="less" scoped>
.summary-wrapper {
  width: 62.5%;
  min-width: 1200px;
  margin: -80px auto 0;
  background: #fff;
  border-radius: 15px;
  position: relative;
  padding-bottom: 100px;
  .summary-contain {
    width: 100%;
    padding: 7.83% 7.42% 0 7.42%;
    color: #333;
    .title {
      font-size: 40px;
      font-weight: 500;
    }
    .divider {
      width: 50px;
      height: 3px;
      background: #333;
      margin: 39px 0 41px 0;
    }
    .text {
      background: #fff;
      color: #666;
      line-height: 32px;
      font-size: 16px;
      &:last-child {
        margin-top: 18px;
      }
    }
  }
  .intro-img {
    width: 100%;
    height: 399px;
    position: relative;
    background-size: 100%;
    background-repeat: no-repeat;
    border-radius: 8px;
    .people-box {
      position: absolute;
      top: 120px;
      left: 8.88%;
    }
    .info {
      .name {
        display: inline-block;
        font-size: 32px;
        color: #ffffff;
      }
      .infoText {
        display: inline-block;
        font-size: 16px;
        color: #ffffff;
        margin-left: 21px;
      }
    }
    .desc {
      width: 484px;
      font-size: 16px;
      font-weight: 400;
      color: #ffffff;
      line-height: 32px;
      text-align: justify;
      margin-top: 30px;
    }
  }
}
</style>
