export default {
  // 线上域名，新官网域名可继续添加
  BEIJING: {
    name: '北京云思智学科技有限公司',
    regex: /yunsizhixue.com|yunsizhixue.cn/,
    copyright: /\.cn$/.test(location.hostname)
      ? '京ICP备2021036633号-3'
      : '京ICP备2021036633号-4'
  },
  GUANGZHOU: {
    name: '北京云思智学科技有限公司广州分公司',
    regex: /yszxgz.com/,
    copyright: '粤ICP备2022141776号-1'
  },
  GHUANGZHOUWL: {
    name: '广州云思智学网络科技有限公司',
    regex: /gzyszxwl.com/,
    copyright: '粤ICP备2022156664号'
  },

  // 线下域名方便调试
  SUANSHUBANG: {
    name: '北京云思智学科技有限公司',
    regex: /suanshubang.cc/,
    copyright: /\.cn$/.test(location.hostname)
      ? '京ICP备2021036633号（yszx）-3'
      : '京ICP备2021036633号（yszx）-4'
  },
  LOCAL: {
    name: '北京云思智学科技有限公司',
    regex: /localhost|test.suanshubang/,
    copyright: /\.cn$/.test(location.hostname)
      ? '京ICP备2021036633号-3'
      : '京ICP备2021036633号-4'
  }
}
